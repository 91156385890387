import { useState } from 'react'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useAsyncEffect } from 'use-async-effect'
import { Button } from '@/components/ui'
import { BrandModel } from '@/models'
import { useSession } from '@/context'

type BrandCTAsProps = {
  brand: BrandModel
  onToggleEditMode: (editMode: boolean) => void
}

const BrandCTAs = ({ brand, onToggleEditMode }: BrandCTAsProps) => {
  const { session, requestWallet, refreshSession } = useSession()
  const [editMode, setEditMode] = useState<boolean>(false)
  const [totalFollowers, setTotalFollowers] = useState<number>(
    brand.item.totalFollowers
  )
  const [followProcessing, setFollowProcessing] = useState<boolean>(false)
  const [userIsFollowing, setUserIsFollowing] = useState<boolean>(false)

  const onFollow = async (reloadSession: boolean) => {
    setFollowProcessing(true)
    let _totalFollowers: number | null = null
    if (reloadSession) {
      const _session = await refreshSession()
      _totalFollowers = await brand.analyticsFollowLog(
        _session,
        userIsFollowing
      )
    } else if (session) {
      _totalFollowers = await brand.analyticsFollowLog(session, userIsFollowing)
    }

    if (_totalFollowers !== null) {
      setTotalFollowers(_totalFollowers)
      setUserIsFollowing(!userIsFollowing)
    }
    setFollowProcessing(false)
  }

  useAsyncEffect(async () => {
    const followStatus = await brand.analyticsFollowStatus()
    await brand.analyticsViewsLog()
    setUserIsFollowing(followStatus.following)
  }, [])

  if (!brand) return <></>
  return (
    <div className="flex gap-2 justify-end">
      {brand.item.ownerId === session?.user?.id && (
        <Button
          text={editMode ? 'Exit Edit Mode' : 'Edit'}
          icon={editMode ? solid('arrow-right-from-bracket') : solid('pencil')}
          iconClassName="h-3"
          size="SM"
          style="SECONDARY_ON_GRAY"
          onClick={() => {
            onToggleEditMode(!editMode)
            setEditMode(!editMode)
          }}
        />
      )}
      <Button
        text={`${userIsFollowing ? 'Following' : 'Follow'} ${totalFollowers}`}
        showloadingIcon={followProcessing}
        icon={followProcessing ? undefined : solid('caret-up')}
        iconClassName="h-3.5 mt-[1px]"
        size="SM"
        style="PRIMARY"
        onClick={async () => {
          if (!session?.wallet) {
            requestWallet({
              description:
                'Please connect your Wallet to follow this comnpany. To connect your wallet now, please choose your preferred provider below.',
              descriptionSuccess:
                "Your wallet is now connected and we've updated your follow status. Thanks for supporting this company.",
              onSuccess: async () => {
                await onFollow(true)
              },
            })
          } else await onFollow(false)
        }}
      />
    </div>
  )
}
export { BrandCTAs }
