import { forwardRef, useState, useRef, useCallback, useEffect } from 'react'
import { useForm, useWatch, SubmitHandler } from 'react-hook-form'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useAsyncEffect } from 'use-async-effect'
import { useApp } from '@/context'
import { request } from '@/utils/http'
import { displayDate } from '@/utils/dates'
import {
  AwesomeIcon,
  ConfirmModal,
  ConfirmModalRef,
  Button,
} from '@/components/ui'
import { TextInput } from '@/components/form'
import { BrandModel, BrandUpdateModel } from '@/models'
import { BrandUpdateItem } from '@/utils/typings/models'

type FormInputs = {
  title: string
  body: string
}
type UpdatesProps = {
  brand?: BrandModel
  onUpdated: () => void
}
export type UpdatesRef = {
  onSubmit: (onSuccess: () => void, onError: () => void) => void
}

const Updates = forwardRef<UpdatesRef, UpdatesProps>((props, ref) => {
  const [items, setItems] = useState<BrandUpdateItem[]>()
  const [item, setItem] = useState<BrandUpdateItem | null>(null)
  const [processing, setProcessing] = useState<boolean>(false)
  const confirmModalDeleteRef = useRef<ConfirmModalRef>(null)
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<FormInputs>()
  const formValues = useWatch({ control })
  const { showError, showNotification } = useApp()
  let { brand, onUpdated } = props
  if (!brand) {
    brand = new BrandModel()
  }

  const resetItem = useCallback(() => {
    setItem(null)
    reset({ title: '', body: '' })
  }, [reset])

  const refreshItems = async () => {
    if (brand?.item.id) {
      const _items = await BrandUpdateModel.getBrandUpdates(brand.item.id)
      if (_items) setItems(_items)
    }
  }

  const onSubmitForm: SubmitHandler<FormInputs> = async (
    formInputs
  ): Promise<boolean> => {
    setProcessing(true)

    if (brand) {
      const res = await request({
        url: `/api/brands/${brand.item.id}/updates/${
          item?.id ? `${item?.id}/update` : 'create'
        }`,
        method: item?.id ? 'PUT' : 'POST',
        params: {
          title: formInputs.title,
          body: formInputs.body,
        },
      })

      if (res) {
        await refreshItems()
        resetItem()
        onUpdated()
      } else {
        showError({ title: 'There was a problem saving the record' })
      }
    }

    setProcessing(false)
    return true
  }

  const onEditItem = (i: BrandUpdateItem) => {
    setItem(i)
    reset({ title: i.title ? i.title : '', body: i.body })
  }

  const onDeleteItem = (i: BrandUpdateItem) => {
    if (confirmModalDeleteRef.current) {
      confirmModalDeleteRef.current.showModal(i)
    }
  }
  const onDeleteItemConfirm = async (i: BrandUpdateItem) => {
    if (i && brand) {
      const updateItem = new BrandUpdateModel(i)
      await updateItem.delete()
      await refreshItems()
      onUpdated()
      showNotification({ title: 'Company Update Deleted' })
    }
  }

  useEffect(() => {
    resetItem()
  }, [resetItem])

  useAsyncEffect(async () => {
    if (brand?.item.id) {
      await refreshItems()
    }
  }, [brand])

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <div className="space-y-6 pt-6 pb-5">
        <div>
          <p className="mt-2 text-base">
            Add Company Updates to help keep your audience up to date on key
            milestones and activities.
          </p>
        </div>
        <div>
          <div className="bg-indigo-10 rounded-xl space-y-6 px-4 sm:px-6 pt-3 pb-4">
            <div>
              <TextInput
                name="title"
                label="Title"
                placeholder="Set an optional title"
                value={item?.title ? item.title : ''}
                register={register}
                errors={errors}
                required={false}
              />
            </div>
            <div>
              <TextInput
                name="body"
                label="Body"
                type="textarea-autoresize"
                placeholder="Keep your audience engaged and in the loop (i.e. a recent achievement, future plans etc)."
                value={item?.body}
                control={control}
                register={register}
                errors={errors}
                required={true}
              />
            </div>

            <>
              <div className="flex items-center">
                <div className="min-w-0 flex-1 flex flex-col sm:flex-row sm:space-y-0 sm:space-x-4 items-center">
                  <Button
                    text={
                      processing
                        ? 'Saving...'
                        : item?.id
                        ? 'Update Company Update'
                        : 'Add Company Update'
                    }
                    onClick={async (e: React.MouseEvent<HTMLElement>) => {
                      e.preventDefault()
                      await handleSubmit(
                        (formData) => {
                          onSubmitForm(formData)
                        },
                        () => {}
                      )()
                    }}
                  />
                  {item?.id && (
                    <Button
                      text="Cancel"
                      style="WHITE"
                      onClick={() => {
                        resetItem()
                      }}
                    />
                  )}
                </div>
              </div>
            </>
          </div>
          <br />
          <>
            <h4 className="mb-2 text-sm font-semibold">Updates</h4>
            <div className="bg-white shadow sm:rounded-md overflow-hidden">
              {items && items.length > 0 ? (
                <>
                  {items.map((i: BrandUpdateItem) => (
                    <div key={i.id}>
                      <div className="block hover:bg-indigo-10">
                        <div className="flex items-center">
                          <div className="min-w-0 flex-1 flex items-center p-1 pl-2">
                            <span className="truncate text-sm text-gray-700">
                              {i.title
                                ? i.title
                                : `Update (${displayDate(i.createdAt)})`}
                            </span>
                          </div>
                          <div className="px-2 py-2">
                            <span className="relative z-0 inline-flex shadow-sm rounded-md">
                              <Button
                                text={
                                  <AwesomeIcon
                                    icon={regular('pencil')}
                                    className="h-3 text-gray-500"
                                  />
                                }
                                style="WHITE_MUTED"
                                className="relative rounded-l-md rounded-r-none"
                                onClick={(e: React.MouseEvent<HTMLElement>) => {
                                  e.preventDefault()
                                  onEditItem(i)
                                }}
                              />
                              <Button
                                text={
                                  <AwesomeIcon
                                    icon={regular('trash')}
                                    className="h-3 text-gray-500"
                                  />
                                }
                                style="WHITE_MUTED"
                                className="-ml-px relative rounded-none"
                                onClick={(e: React.MouseEvent<HTMLElement>) => {
                                  e.preventDefault()
                                  onDeleteItem(i)
                                }}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <div>
                  <span className="block text-gray-300 hover:bg-indigo-10 hover:text-gray-400">
                    <div className="flex items-center px-4 py-4 sm:px-6">
                      <p className="text-sm font-medium truncate">
                        No Company Updates found.
                      </p>
                    </div>
                  </span>
                </div>
              )}
            </div>
          </>
        </div>
      </div>
      <ConfirmModal
        ref={confirmModalDeleteRef}
        title="Are you sure?"
        buttonStyle="DANGER"
        description="To delete this Company Update, press the button below. Once deleted it cannot be recovered."
        onConfirm={onDeleteItemConfirm}
      />
    </form>
  )
})
export { Updates }
