import { useState, useEffect, Fragment } from 'react'
import { Tab } from '@headlessui/react'
import { PageWrapper } from '@/components/app'
import { PageProps } from '@/utils/typings/app'
import { BrandItem, ListingItem } from '@/utils/typings/models'
import { GetServerSideProps } from 'next'
import { ListingList } from '@/components/models/listing/page'
import { BrandHeader, BrandUpdates } from '@/components/models/brand/page'
import { BrandModel, ListingModel } from '@/models'
import { useApp } from '@/context'

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export const getServerSideProps: GetServerSideProps = async (context) => {
  const { params } = context
  if (params && params.brandSlug) {
    const brand = await BrandModel.getBrandBySlugRaw(
      params.brandSlug.toString()
    )
    if (brand) {
      const { items: listingItems } =
        await ListingModel.getPublishedListingsRaw({ brands: [brand.id] })
      if (brand) {
        return { props: { brandItem: brand, listingItems: listingItems } }
      }
    }
  }
  return { props: {} }
}

interface BrandProps extends PageProps {
  brandItem: BrandItem
  listingItems: ListingItem[]
}

const Brand = ({ brandItem, listingItems }: BrandProps) => {
  const [brand, setBrand] = useState<BrandModel | null>()
  const [listings, setListings] = useState<ListingModel[]>()
  const [editMode, setEditMode] = useState<boolean>(false)
  const { setPageMeta } = useApp()
  const refreshBrand = async () => {
    if (brand?.item.slug) {
      setBrand(await BrandModel.getBrandBySlug(brand.item.slug))
    }
  }

  const onContentUpdated = async () => {
    await refreshBrand()
  }

  const onToggleEditMode = (_editMode: boolean) => {
    setEditMode(_editMode)
  }

  const onBrandUpdated = (updatedBrand: BrandModel) => {
    if (updatedBrand) setBrand(updatedBrand)
  }

  useEffect(() => {
    if (brandItem) {
      const brandModel = new BrandModel(brandItem)
      setBrand(brandModel)

      setPageMeta({
        title: `${brandItem.name}`,
        description: brandItem.description ? brandItem.description : '',
        ogImageUrl: brandModel.logoUrlSquareFull
          ? brandModel.logoUrlSquareFull
          : '',
      })
    }

    if (listingItems) {
      setListings(
        listingItems.map((item: ListingItem) => new ListingModel(item))
      )
    }
  }, [brandItem, listingItems, setPageMeta])

  if (!brand) return <></>
  return (
    <>
      <div className="bg-gray-50 pb-16">
        <BrandHeader
          brand={brand}
          editMode={editMode}
          onBrandUpdated={onBrandUpdated}
          onToggleEditMode={onToggleEditMode}
        />

        <div className="max-w-7xl mx-auto mt-12 px-4 sm:px-6 lg:px-8">
          <div className="flex">
            <div className="flex-1 pr-8">
              <div className="width-full mx-auto mt-16 space-y-8 lg:mt-0">
                <p className="whitespace-pre-line">{brand.item.description}</p>

                <Tab.Group as="div">
                  <div className="border-b border-gray-200">
                    <Tab.List className="-mb-px flex space-x-8">
                      <Tab
                        className={({ selected }) =>
                          classNames(
                            selected
                              ? 'border-indigo-600 text-indigo-600'
                              : 'border-transparent text-gray-700 hover:text-gray-800 hover:border-gray-300',
                            'whitespace-nowrap py-6 border-b-2 font-medium text-md'
                          )
                        }
                      >
                        Updates
                      </Tab>
                    </Tab.List>
                  </div>
                  <div className="mt-8 relative">
                    <Tab.Panels as={Fragment}>
                      <Tab.Panel>
                        <BrandUpdates
                          brand={brand}
                          editMode={editMode}
                          onUpdated={onContentUpdated}
                        />
                      </Tab.Panel>
                    </Tab.Panels>
                  </div>
                </Tab.Group>
              </div>
            </div>
            <div className="w-64">
              <div className="sticky top-[90px] space-y-6">
                {listings && <ListingList listings={listings} mode="SIDEBAR" />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PageWrapper(Brand, {
  pageTitle: '',
  layout: {
    mode: 'PUBLIC',
    allowContentFullWidth: true,
    disablePaddingTop: true,
    headerShadow: true,
  },
})
