import { useState } from 'react'
import {
  regular,
  duotone,
  brands,
} from '@fortawesome/fontawesome-svg-core/import.macro'
import { BrandModel } from '@/models'
import { AwesomeIcon, Tooltip } from '@/components/ui'
import { ImageInput } from '@/components/form'
import { displayUrl, addParamToUrl } from '@/utils/misc'
import { BrandCTAs } from './BrandCTAs'

type BrandHeaderProps = {
  brand: BrandModel
  editMode: boolean
  onBrandUpdated: (brand: BrandModel) => void
  onToggleEditMode: (editMode: boolean) => void
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const BrandHeader = ({
  brand,
  editMode,
  onBrandUpdated,
  onToggleEditMode,
}: BrandHeaderProps) => {
  const [headerImageUploading, setHeaderImageUploading] =
    useState<boolean>(false)
  const [logoImageUploading, setLogoImageUploading] = useState<boolean>(false)

  if (!brand) return <></>
  return (
    <>
      <div>
        <div className="relative group overflow-hidden">
          <img
            className="h-32 w-full object-cover lg:h-48"
            src={
              brand.item.pageHeaderImage
                ? `${process.env.NEXT_PUBLIC_SUPABASE_PUBLIC_STORAGE_URL}/brands/${brand.item.id}/${brand.item.pageHeaderImage}`
                : 'https://images.unsplash.com/photo-1444628838545-ac4016a5418a?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80'
            }
            alt=""
          />
          {editMode && (
            <>
              <div
                className={classNames(
                  'absolute inset-0 transition-opacity duration-300 ease-in-out group-hover:bg-gray-500 group-hover:opacity-75',
                  headerImageUploading ? 'opacity-100' : 'opacity-0'
                )}
              />
              <div
                className={classNames(
                  'absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center transition-opacity duration-300 ease-in-out group-hover:opacity-100',
                  headerImageUploading ? 'opacity-100' : 'opacity-0'
                )}
              >
                <ImageInput
                  bucket="brands"
                  folder={brand.item.id}
                  mode="button-only"
                  buttonText="Change Background"
                  buttonIcon={
                    headerImageUploading
                      ? duotone('spinner-third')
                      : regular('camera')
                  }
                  buttonIconClassName={
                    headerImageUploading ? 'h-4 fa-spin' : 'h-4'
                  }
                  buttonSize="MD"
                  buttonStyle="WHITE"
                  autoUpload={false}
                  onChange={async (file: File) => {
                    setHeaderImageUploading(true)
                    let formData = new FormData()
                    formData.append('pageHeaderImage', file)
                    const updatedBrand = await brand.updatePage(formData)
                    if (updatedBrand) onBrandUpdated(updatedBrand)
                    setHeaderImageUploading(false)
                  }}
                />
              </div>
            </>
          )}
        </div>
        <div className="relative max-w-7xl mx-auto px-4 pt-[30px] sm:px-6 lg:px-8">
          <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
            <div className="flex relative group">
              <img
                className="h-24 w-24 rounded-full ring-4 ring-white sm:h-32 sm:w-32"
                src={brand.logoUrlSquareFull}
                alt=""
              />
              {editMode && (
                <div className="absolute h-24 w-24 rounded-full sm:h-32 sm:w-32 overflow-hidden">
                  <div
                    className={classNames(
                      'absolute inset-0 transition-opacity duration-300 ease-in-out group-hover:bg-gray-500 group-hover:opacity-75',
                      logoImageUploading ? 'opacity-100' : 'opacity-0'
                    )}
                  />
                  <div
                    className={classNames(
                      'absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center group-hover:opacity-100',
                      logoImageUploading ? 'opacity-100' : 'opacity-0'
                    )}
                  >
                    <ImageInput
                      bucket="brands"
                      folder={brand.item.id}
                      mode="button-only"
                      buttonText=""
                      buttonIcon={
                        logoImageUploading
                          ? duotone('spinner-third')
                          : regular('camera')
                      }
                      buttonIconClassName={
                        logoImageUploading ? 'h-4 fa-spin' : 'h-4'
                      }
                      buttonSize="MD"
                      buttonStyle="WHITE"
                      autoUpload={false}
                      onChange={async (file: File) => {
                        setLogoImageUploading(true)
                        let formData = new FormData()
                        formData.append('logoUrlSquare', file)
                        const updatedBrand = await brand.updatePage(formData)
                        if (updatedBrand) onBrandUpdated(updatedBrand)
                        setLogoImageUploading(false)
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="mt-6 sm:flex-1 sm:min-w-0 sm:flex sm:items-end sm:justify-end sm:space-x-6">
              <div className="sm:hidden md:block mt-6 min-w-0 flex-1">
                <h1 className="text-2xl font-bold text-gray-900 flex items-center">
                  {brand.item.name}
                  {brand.item.isVerified && (
                    <Tooltip
                      text="This brand has been verified"
                      position="top"
                      className=""
                    >
                      <AwesomeIcon
                        icon={duotone('circle-check')}
                        className="ml-2 text-green-500 text-base"
                      />
                    </Tooltip>
                  )}
                </h1>
                <p className="text-sm font-medium text-gray-500">
                  <a
                    href={addParamToUrl(brand.item.url, 'ref', 'floor3')}
                    target="_blank"
                    className="underline"
                    rel="noreferrer"
                  >
                    {displayUrl(brand.item.url)}
                  </a>{' '}
                  <span className="text-gray-300 mx-1">|</span>{' '}
                  {brand.industryName}{' '}
                  <span className="text-gray-300 mx-1">|</span>{' '}
                  {brand.countryName}{' '}
                  <span className="text-gray-300 mx-1">|</span> Founded{' '}
                  {brand.item.founded}
                </p>
                <p className="mt-1 text-sm font-medium text-gray-500 flex items-center gap-x-3">
                  {brand.item.socialUrlTwitter && (
                    <a
                      href={addParamToUrl(
                        brand.item.socialUrlTwitter,
                        'ref',
                        'floor3'
                      )}
                      target="_blank"
                      className="underline"
                      rel="noreferrer"
                    >
                      <AwesomeIcon
                        icon={brands('twitter')}
                        className="text-twitter hover:text-gray-600"
                      />
                    </a>
                  )}
                  {brand.item.socialUrlFacebook && (
                    <a
                      href={addParamToUrl(
                        brand.item.socialUrlFacebook,
                        'ref',
                        'floor3'
                      )}
                      target="_blank"
                      className="underline"
                      rel="noreferrer"
                    >
                      <AwesomeIcon
                        icon={brands('facebook')}
                        className="text-facebook hover:text-gray-600"
                      />
                    </a>
                  )}
                  {brand.item.socialUrlLinkedIn && (
                    <a
                      href={addParamToUrl(
                        brand.item.socialUrlLinkedIn,
                        'ref',
                        'floor3'
                      )}
                      target="_blank"
                      className="underline"
                      rel="noreferrer"
                    >
                      <AwesomeIcon
                        icon={brands('linkedin')}
                        className="text-linkedin hover:text-gray-600"
                      />
                    </a>
                  )}
                  {brand.item.socialUrlInstagram && (
                    <a
                      href={addParamToUrl(
                        brand.item.socialUrlInstagram,
                        'ref',
                        'floor3'
                      )}
                      target="_blank"
                      className="underline"
                      rel="noreferrer"
                    >
                      <AwesomeIcon
                        icon={brands('instagram')}
                        className="text-instagram hover:text-gray-600"
                      />
                    </a>
                  )}
                  {brand.item.socialUrlTikTok && (
                    <a
                      href={addParamToUrl(
                        brand.item.socialUrlTikTok,
                        'ref',
                        'floor3'
                      )}
                      target="_blank"
                      className="underline"
                      rel="noreferrer"
                    >
                      <AwesomeIcon
                        icon={brands('tiktok')}
                        className="text-tiktok hover:text-gray-600"
                      />
                    </a>
                  )}
                </p>
              </div>
              <div className="justify-stretch mt-6 sm:flex-row">
                <BrandCTAs brand={brand} onToggleEditMode={onToggleEditMode} />
              </div>
            </div>
          </div>
          <div className="hidden sm:block md:hidden mt-6 min-w-0 flex-1">
            <h1 className="text-2xl font-bold text-gray-900 truncate">
              {brand.item.name}
            </h1>
            <p className="text-sm font-medium text-gray-500">
              By{' '}
              <a href={`/${brand.item.slug}`} className="default">
                {brand.item.name}
              </a>{' '}
            </p>
          </div>
        </div>
      </div>
    </>
  )
}
export { BrandHeader }
