import { useRef } from 'react'
import {
  duotone,
  regular,
} from '@fortawesome/fontawesome-svg-core/import.macro'
import { BrandModel } from '@/models'
import { Button, AwesomeIcon, SlideOver, SlideOverRef } from '@/components/ui'
import { BrandUpdateItem } from '@/utils/typings/models'
import { Updates } from '@/components/models/brand/page/manage/Updates'
import { displayDate } from '@/utils/dates'

type BrandUpdatesProps = {
  brand: BrandModel
  editMode: boolean
  onUpdated: () => void
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

type EditModeButtonProps = {
  editMode: boolean
  slideOverRef: React.RefObject<SlideOverRef>
}

const EditModeButton = ({ editMode, slideOverRef }: EditModeButtonProps) => {
  if (!editMode) return <></>
  return (
    <div className="absolute -top-[84px] right-0 mb-4 flex justify-end">
      <Button
        text="Manage Updates"
        style="SECONDARY_ON_GRAY"
        size="XS"
        icon={regular('calendar-lines-pen')}
        onClick={() => {
          if (slideOverRef.current) {
            slideOverRef.current.openSlideOver()
          }
        }}
      />
    </div>
  )
}

const BrandUpdates = ({ brand, editMode, onUpdated }: BrandUpdatesProps) => {
  const slideOverRef = useRef<SlideOverRef>(null)

  if (!brand || !brand.item.updates) return <></>
  return (
    <>
      <EditModeButton editMode={editMode} slideOverRef={slideOverRef} />
      {brand.item.updates.length === 0 ? (
        <div className="text-gray-400 text-sm py-3 px-4 bg-gray-100 rounded-lg">
          This company has not posted any updates yet. Follow this company to be
          notified when they post an update.
        </div>
      ) : (
        <>
          {brand.item.updates.map((item: BrandUpdateItem, index: number) => (
            <div
              key={item.id}
              className={classNames(
                'shadow rounded-lg bg-white px-8 py-6 mb-8'
              )}
            >
              <div className="flex justify-between items-center">
                <p className="font-semibold text-gray-900 text-md mb-4">
                  {item.title ? item.title : 'Update'}
                </p>
                <p className="font-medium text-gray-400 text-xs mb-2 text-right flex items-center">
                  <AwesomeIcon
                    icon={duotone('calendar')}
                    className="mr-2 -mt-[1px] text-gray-300"
                  />
                  <span>{displayDate(item.createdAt)}</span>
                </p>
              </div>
              <p className="text-sm text-gray-500 whitespace-pre-line">
                {item.body}
              </p>
            </div>
          ))}
        </>
      )}

      <SlideOver ref={slideOverRef} title="Company Updates" maxWidth="md">
        <Updates brand={brand} onUpdated={onUpdated} />
      </SlideOver>
    </>
  )
}
export { BrandUpdates }
